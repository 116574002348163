import { useState, useEffect } from "react";

function getStorageValue(key, defaultValue) {
  // getting stored value
  const saved = localStorage.getItem(key);
  const initial = JSON.parse(saved);
  return initial || defaultValue;
}

export const useLocalStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue);
  });

  useEffect(() => {
    // storing input name
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

export const useEvent = (event, handler, passive = false) => {
    useEffect(() => {
      // initiate the event handler
      addEventListener(event, handler, passive);
  
      // this will clean up the event every time the component is re-rendered
      return function cleanup() {
        removeEventListener(event, handler);
      };
    });
};

