import React from "react";
import Meta from "./../Meta";
import Script from '@gumgum/react-script-tag';
import { useEvent } from '../../hooks';
import { useRouter } from '../../util/router';
import OGDialogBox from "./OGDialogBox";
import { isMobile } from 'react-device-detect';
import { ogLog } from "../../util/db";

const opengridCanvasStyle = {
    width: '100%',
    height: '100vh',
    minHeight: '100vh',
    backgroundImage: 'url("https://res.cloudinary.com/dq5lw8oy1/image/upload/e_blur:369/v1653076776/OpenGridComplex/OG_CL_ESPN_LoadingScreenImage_web_lggjqz.jpg")',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
}

const ogContainerStyle = {
    margin: 'auto',
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: '100 !important',
    height: '100vh',
    width: '100vw',
}

const buildConfig = (filename) => {
    return {
        dataUrl: `./BuildDay2/Build/${filename}.data`,
        frameworkUrl: `./BuildDay2/Build/${filename}.framework.js`,
        codeUrl: `./BuildDay2/Build/${filename}.wasm`,
        loaderUrl: `./BuildDay2/Build/Build.loader.js`,
        streamingAssetsUrl: `./BuildDay2/StreamingAssets`,
        companyName: "Open Grid",
        productName: "opengrid_retail",
        productVersion: "0.2",
    }
}

const OpenGridContainer = (props) => {
    const [build, setBuild] = React.useState(null);
    const [openGridHasLoaded, setOpenGridHasLoaded] = React.useState(false);
    const [initialSceneLoad, setInitialSceneLoad] = React.useState(true);
    const [isLocker, setIsLocker] = React.useState(false);

    const router = useRouter();

    const isIos154 = navigator.userAgent.match('15_4');
    const isWebview = navigator.userAgent.match('wv');

    React.useEffect(() => {
        if (openGridHasLoaded) {
            const mobileMode = isMobile ? "1" : "0";
            build.SendMessage("GameController", "SetMobileMode", mobileMode);
            ogLog('loaded_ui_manager');
            if (isLocker) dispatchEvent(new Event('form'));
        }
    }, [openGridHasLoaded]);

    React.useEffect(() => {
        if (router.pathname === '/') refocus();
    }, [router.pathname]);
    useEvent('loaded_ui_manager', () => setOpenGridHasLoaded(true));
    useEvent('load_ready', () => {
        if (initialSceneLoad)
        {
            build.SendMessage("LoadingScreen", "SelectScene", 'tunnel');
            setInitialSceneLoad(false);
        }
    });
    useEvent('request_gyro', () => {
        if (!isWebview) {
            build.SendMessage("GameController", "EnableGyro");
        }
    });

    useEvent('AnswerPhone', () => ogLog('answer_phone'));
    useEvent('EndPhone', () => ogLog('end_phone'));
    useEvent('ReplayPhone', () => ogLog('replay_phone'));

    useEvent('scene_loaded', (e) => {
        const scene = e.detail.scene;
        if (scene === 'locker') {
            ogLog('loaded_second_scene');
            setIsLocker(true);
            setOpenGridHasLoaded(false);
        }
    })

    const refocus = () => { if (build) build.SendMessage("GameController", "FocusCanvas", "1") };
    const unfocus = () => { if (build) build.SendMessage("GameController", "FocusCanvas", "0") };
    
    const onLoad = () => {
        if (isIos154) alert("Your device’s OS (operating system) is out of date.  Please update to the latest available version for your device through the settings menu or open this url on a laptop or desktop.")
        else setTimeout(() =>
            window.createUnityInstance(document.getElementById('opengrid-canvas'), buildConfig('Build'))
                .then((buildInstance) => setBuild(buildInstance)
                    .then(ogLog('initial_load'))
                    .catch((message) => {
                        alert(message);
                    }),
                    500));   
    }
    
    return (<>
      <Meta title="ESPN x NBA x Complexland | Powered by Open Grid" />
            <Script type="text/javascript" src={`${window.location.protocol}//${window.location.host}/BuildDay2/Build/Build.loader.js`} onLoad={() => onLoad()} />
            <div style={ogContainerStyle}>
                <canvas id="opengrid-canvas" style={opengridCanvasStyle} />
            </div>
        <OGDialogBox unfocus={unfocus} refocus={refocus} />
    </>);
}

export default OpenGridContainer;

       
   