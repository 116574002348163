import React from "react";
import TagManager from 'react-gtm-module';
import Dialog from "@material-ui/core/Dialog";
import LegalPage from "./legal";
import { Switch, Route, Router } from "./../util/router";
import NotFoundPage from "./404";
import { ThemeProvider } from "./../util/theme";
import { QueryClientProvider } from "./../util/db";
import OpenGridContainer from "../components/OpenGrid/OpenGridContainer";
import '../assets/onetrust-cl.scss';

function App() {
  React.useEffect(() => {
    TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_TAG })
  }, []);
  
  return (
    // FOR IFRAME ONLY EXPERIENCES
    // <>{
    //   window.self === window.top ? (window.location.replace('https://complexland.com'))
    //     :
    <>
        <QueryClientProvider>
          <ThemeProvider>
            <Router>
                  <>
                    <OpenGridContainer />
                
                    <Switch>
                      <Route exact path="/" component={null} />

                      <Route exact path="/legal/:section" component={LegalPage} />

                      <Route component={NotFoundPage} />
                    </Switch>

                  </>
            </Router>
          </ThemeProvider>
        </QueryClientProvider>
    </>
    );
}

export default App;
