import React from "react";
import LongContent from "../LongContent";
import Typography from "@material-ui/core/Typography";

function LegalTerms(props) {
  return (
    <LongContent>

<Typography variant="h4"><a href="https://opengrid.xyz">OPEN GRID, INC.</a></Typography>
<Typography variant="h4">TERMS OF USE</Typography>

<Typography variant="h5">Effective Date: July 23, 2021</Typography>
<Typography variant="h6" component={'h6'}>Dear user (“you” or “your”)</Typography>
<Typography>These Terms of Use (the “Terms”) set out the terms in which Open Grid, Inc. (“Open
Grid”, “we”, “us” or “our”) will provide access to and use of its proprietary virtual and
augmented reality platform for experiential online platform design and development
and certain other related services available on or through our website (the “Site”)
including, software applications or future mobile applications (collectively, the
“Service”) to you, a user of the Service (“you” or “your” or “User” or “Users”).
This Site is operated by Open Grid. By visiting this Site, or providing your information
to us, you are accepting these Terms and those of our Privacy Policy (the “Privacy
Policy”). The Privacy Policy is incorporated into these Terms by this reference.</Typography>
<Typography variant="h5">GENERAL CONDITIONS</Typography>
<Typography variant="h6" component={'h6'}>Nature of the Service</Typography>
<Typography>The Service allows Users to access information or other content (which may include
data, text, photos or other materials or content) related to platform development in a
virtual and augmented reality setting to enable an immersive online experience. It
may also permit certain Users to create, upload, store and/or transmit to other Users
certain information or other content (which may include data, text, photos or other
materials or content) (collectively, “User Content”). Your User Content may also be
referred to as “Your Content” herein.</Typography>
<Typography>By creating, posting and submitting User Content on our Site, you grant Open Grid
and its subsidiaries, affiliates, and service providers, and to each of their and our
respective licensees, successors and assigns, a perpetual, irrevocable, royalty-free,
worldwide, sublicensable and transferable license to use, copy, store, publish,
translate, modify, create derivative works from, distribute, reproduce, display, or
exploit the User Content in all formats, by any means and on all media, whether now
known or hereafter discovered, including but not limited to on the Site and in any
commercial or non-commercial manner whatsoever.</Typography>
<Typography>Open Grid shall have no obligation to display any User Content, nor compensate you
for submitting User Consent nor respond to any submission. We retain the right, in
our sole discretion and without prior notice, to remove, revise or refuse to post any
User Content for any reason or no reason.</Typography>
<Typography>Notwithstanding the foregoing, personally identifiable data, if any, included in User
Content shall be handled in accordance with our Privacy Policy.</Typography>
<Typography>You represent and warrant that:</Typography>
<Typography>- you own all rights in and to your User Content, if the User Content is subject
to third party proprietary rights, you have all necessary licenses, rights,
consents, and permissions to publish the User Content you submit and to
grant the rights granted herein, including permission from all person(s)
appearing in your User Content;</Typography>
<Typography>- the User content does not contain any false or misleading information,
libelous, defamatory, obscene, offensive, threatening or otherwise harassing
content, contain any addresses, email addresses, phone numbers or any
contact information, or contain computer viruses, worms or other harmful files;
and</Typography>
<Typography>- all your User Content does and will comply with these Terms and all
applicable law.</Typography>
<Typography>In addition, you warrant that all so-called &quot;moral rights&quot; in those materials have been
waived.</Typography>
<Typography>You are solely responsible for the User Content and you hereby agree to indemnify
and hold Open Grid and its officers, employees, agents partners, and directors and
affiliates harmless from any and all damages, claims, expenses, costs or fees arising
from or in connection with a breach of any of the foregoing representations or your
violation of any law or rights of a third party.</Typography>
<Typography component={'h6'}>Age</Typography>
<Typography>Any use or access by anyone under the age of 18 is prohibited. Users must be at
least 18 years of age to create an account on our Site and use the Services.</Typography>
<Typography variant="h6" component={'h6'}>Registration</Typography>
<Typography variant="body1">In order to use certain parts of the Service, you may be required to provide us with
your email address. This address will be used to arrange gift distribution only. To the
extent you are using the Service on behalf of an organization, you may need to also
provide us with information in order to confirm, or permit us to confirm, any
relationship between you and such organization. We may also request additional
information from you. You represent and warrant to us that you will provide us with
accurate, current and complete registration information. You are responsible for your
registration, and for all use of the Service using any User credentials or passwords
issued to you or chosen by you. You will keep all such credentials and passwords
confidential.</Typography>





<Typography>You also agree that you will create, access, and/or use only one user account,
unless expressly permitted by Open Grid, and you will not share access to your
account or access information for your account with any third party. Using our
Services or Site information or features does not give you ownership of or any
intellectual property rights in our Service or other content you access.
You are responsible for your account and may not share your login credentials with
anyone else. You agree to update your information to keep it accurate and complete.</Typography>
<Typography variant="h6">Intellectual Property</Typography>
<Typography>The Service and all aspects of Open Grid’s websites, software applications and
mobile applications, including, without limitation, its graphics, text, software,
algorithms, functionality, inventions, concepts, images, sound, music, videos, marks,
logos, compilations, content and technology used to deliver the Service or otherwise
embodied in other intellectual property in such material (collectively, “Intellectual
Property”) is the property of Open Grid protected by copyright, trademark, patent
and other intellectual property or proprietary rights. The work includes works that are
licensed to Open Grid and all rights thereto are specifically reserved. Additionally,
any formats, templates, methodologies, rules, algorithms and software used to
create User Content are our Intellectual Property.</Typography>
<Typography>Except as otherwise expressly permitted by this Agreement, any use, copying,
making derivative works, transmitting, posting, linking, deep linking, framing,
redistribution, sale, decompilation, modification, reverse engineering, translation or
disassembly of our Intellectual Property is prohibited. You acknowledge that our
Intellectual Property has been created, compiled, developed and maintained by us at
great expense of time and money such that misappropriation or unauthorized
disclosure or use of our Intellectual Property by others for commercial gain would
unfairly and irreparably harm us in a manner for which damages would not be an
adequate remedy, and you consent to our obtaining injunctive relief to restrain any
breach or threatened breach of these Terms, without any requirement to post bond.
You may be subject to criminal or civil penalties for violation of this paragraph.
Except as may be otherwise indicated, you are authorized to view, play, print and
download documents, audio and video found on our Site for personal, informational,
and non-commercial purposes related to placing an order on the Site. Any other use
of any content included on the Site is strictly prohibited unless you first obtain our
prior written consent.</Typography>
<Typography>You may not use, copy, distribute, transmit, display, post, perform, reproduce,
publish, license, create derivative works from, transfer or sell any information or work
or modify any of the materials contained on this Site in any way including without
prior written consent.</Typography>
<Typography>The Service may also feature the trademarks, service marks and logos of third
parties, and images and information promoting or otherwise relating to the products
of third parties (collectively, “Third Party Property”). Each owner retains all rights in
his, her or its Third-Party Property. Any use of Third-Party Property, or any other
marks, logos, images or information displayed on the Service, will inure solely to the
benefit of their respective owners. Open Grid is not endorsed, sponsored, or
approved by, or otherwise affiliated with, any of the brands, brand owners, or
representatives thereof featured on the Service or our Site.</Typography>
<Typography variant="h6" component={'h6'}>Optional Tools</Typography>
<Typography>We may provide you with access to third-party tools over which we neither monitor
nor have any control nor input.</Typography>
<Typography>You acknowledge and agree that we provide access to such tools “as is” and “as
available” without any warranties, representations, or conditions of any kind and
without any endorsement. We shall have no liability whatsoever arising from or
relating to your use of optional third-party tools.
Any use by you of optional tools offered through the Site is entirely at your own risk
and discretion and you should ensure that you are familiar with and approve of the
terms on which tools are provided by the relevant third-party provider(s).
We may also, in the future, offer new services and/or features through the Site
(including, the release of new tools and resources). Such new features and/or
services shall also be subject to these Terms.</Typography>
<Typography variant="h6" component={'h6'}>Third-Party Links</Typography>
<Typography >Certain content, products, and services available via our Site and Service may
include materials from third parties.</Typography>
<Typography>
Third-party links on this Site may direct you to third-party websites that are not
affiliated with us. We are not responsible for examining or evaluating the content or
accuracy and we do not warrant and will not have any liability or responsibility for
any third-party materials or websites, or for any other materials, products, or services
of third parties.</Typography>
<Typography>We are not liable for any harm or damages related to the purchase or use of goods,
services, resources, content, or any other transactions made in connection with any
third-party websites. Please review carefully the third-party&#39;s policies and practices
and make sure you understand them before you engage in any transaction.
Complaints, claims, concerns, or questions regarding third-party products should be
directed to the third-party.</Typography>
<Typography variant="h6" component={'h6'}>Personal Information</Typography>
<Typography>Your submission of personal information through the Site is governed by our Privacy
Policy.</Typography>
<Typography variant="h6" component={'h6'}>Errors, Inaccuracies and Omissions</Typography>
<Typography>Occasionally there may be information on our Site that contains typographical errors,
inaccuracies or omissions that may relate to our Service. We reserve the right to
correct any errors, inaccuracies or omissions, and to change or update information
or cancel payments if any information on our Site or in the Service or on any related
website that is inaccurate at any time without prior notice.
We undertake no obligation to update, amend or clarify information on or related to
the Site and Service, except as required by law.
</Typography>
<Typography variant="h6" component={'h6'}>Prohibited Uses</Typography>
<Typography>In addition to other prohibitions as set forth in the Terms, you are prohibited from
using the Site or its content: (a) for any unlawful purpose; (b) to solicit others to
perform or participate in any unlawful acts; (c) to violate any international, federal,
provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or
violate our intellectual property rights or the intellectual property rights of others; (e)
to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or
discriminate based on gender, sexual orientation, religion, ethnicity, race, age,
national origin, or disability; (f) to submit false or misleading information; (g) to
upload or transmit viruses or any other type of malicious code that will or may be
used in any way that will affect the functionality or operation of the Service or of any
related website, other websites, or the Internet; (h) to collect or track the personal
information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j)
for any obscene or immoral purpose; or (k) to interfere with or circumvent the
security features of the Service or any related website, other websites, or the
Internet. We reserve the right to terminate your use of the Site and our Service or
any related website for any of the aforementioned violations.</Typography>

<Typography variant="h6" component={'h6'}>Disclaimer of Warranties; Limitation of Liability</Typography>

<Typography>We do not guarantee, represent, or warrant that your use of our Services will be
uninterrupted, timely, secure, or error-free.</Typography>
<Typography>We do not warrant that the results that may be obtained from the use of the Site or
our Services will be accurate or reliable.</Typography>
<Typography>You agree that from time to time we may remove the Services for indefinite periods
of time or cancel the Services at any time, without notice to you.</Typography>
<Typography>You expressly agree that your use of, or inability to use, the Services is at your sole
risk. The Services and all products and services delivered to you through the Site are
(except as expressly stated by us)</Typography>

    </LongContent>
  );
}

export default LegalTerms;
