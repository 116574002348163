import React from 'react';
import { useEvent } from '../../hooks';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { isMobile } from 'react-device-detect';
import { Fullscreen, FullscreenExit } from "@material-ui/icons";
import { ogLog } from '../../util/db';
import LegalTerms from '../Legal/LegalTerms';
import LegalPrivacy from '../Legal/LegalPrivacy';
import '../../assets/styles.css';
import ReactPlayer from 'react-player';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
  
function OGDialogBox(props) {
    const { unfocus, refocus } = props;
    const [open, setOpen] = React.useState(false);
    const [fullScreen, setFullScreen] = React.useState(false);
    const [modalID, setModalID] = React.useState(null);
    const [docID, setDocId] = React.useState('');
    const [justExited, setJustExited] = React.useState(null);

    const handleModal = (mid, did = '') => {
        if (!justExited) {
            ogLog('open_' + mid + did);
            setModalID(mid);
            if (did !== '') setDocId(did);
            setOpen(true);
            unfocus();
        }
    }

    const handleClose = () => {
        ogLog('close_' + modalID + docID);
        setJustExited(true);
        setOpen(false);
        setModalID('');
        refocus();
        setTimeout(() => setJustExited(false), 500);
    }

    const handleViewVideo = () => {
        ogLog('ViewVideoModal');
        setModalID('ViewVideoModal');
        unfocus();
    }
 
    useEvent('ViewScheduleModal', () => handleModal('ViewScheduleModal'));
    useEvent('ViewVideoModal', () => handleViewVideo());
    useEvent('ViewInfoModal_L01', () => handleModal('form', "sweepstakes_espn_1"));
    useEvent('ViewInfoModal_L02', () => handleModal('form', "sweepstakes_espn_2"));
    useEvent('ViewInfoModal_L03', () => handleModal('form', "sweepstakes_espn_3"));
    useEvent('ViewInfoModal_L04', () => handleModal('form', "sweepstakes_espn_4"));
    useEvent('ViewInfoModal_L05', () => handleModal('form', "sweepstakes_espn_5"));
    useEvent('ViewInfoModal_L06', () => handleModal('form', "sweepstakes_espn_6"));
    useEvent('ViewInfoModal_L07', () => handleModal('form', "sweepstakes_espn_7"));
    useEvent('ViewInfoModal_L08', () => handleModal('form', "sweepstakes_espn_8"));
    useEvent('ViewGoggleSweepstakes', () => handleModal('form', 'sweepstakes_espn_goggles'))
    useEvent('form', () => handleModal('form', 'sweepstakes_espn_shirt'));
    useEvent('ViewOpenGrid', () => window.open('https://opengrid.xyz/?source=espn'));
    useEvent('ViewPrivacyPolicy', () => handleModal('ViewPrivacyPolicy'));
    useEvent('ViewTerms', () => handleModal('ViewTerms'));
    useEvent('check_dialog_focus', () => { open ? unfocus() : refocus() })
    useEvent('return_to_website', () =>  handleModal('return_to_website')); 


    const returnToComplexland = () => {
        ogLog('return_to_complexland');
        window.close();
        location.replace('https://complexland.com');
    }

    return (<>
        {modalID === 'ViewVideoModal' && (<Box width={'100vw'} height={'100%'} margin='auto' position={'absolute'} overflow={'hidden'} className="MuiBackdrop-root" zIndex={10000000}>
            <IconButton style={{ position: 'absolute', top: '5px', right: '5px', zIndex: 1000 }} onClick={() => {
                setModalID('');
                refocus();
            }}>
                <CloseIcon fontSize='large' style={{ position: 'fixed', zIndex: 1000, color: 'gray' }} />
            </IconButton>
            <Box textAlign='center' overflow={'hidden'}>
                <ReactPlayer url="https://res.cloudinary.com/hqsibq8j6/video/upload/q_auto/v1652907632/Open%20Grid/OG_CL_ESPN_Finals30s_Edit034_Sound_alaas4.mp4"
                    playing={true}
                    width={'85vw'}
                    height={'auto'}
                    controls={true}
                    style={{  margin: 'auto', position:'absolute', top: "50%", left: '50%', overflow: 'hidden',
                    transform: "translate(-50%, -50%)" }}
                />
                </Box>
        </Box>)
        }
            <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} TransitionComponent={Transition} style={{ backgroundColor: 'transparent' }}>
                {modalID === 'form' && (
                    <iframe
                        style={{ width: '100%', height: '100vh', border: 'none', minWidth: !isMobile && '600px',  backgroundColor: 'black', scrollbarColor: 'grey gray' }}
                        src={`https://bin.complex.com/websites/espn-complexland-forms-2022/${docID}.html`}
                    />
                )}

                {modalID === 'ViewScheduleModal' && (<Box pt={2}>
                    <DialogTitle id="responsive-dialog-title">
                        {"Check out the NBA Playoff Schedule?"}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                      By clicking Yes, this will open up a new tab.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => handleClose()}>
                      No
                        </Button>
                        <a href='https://www.espn.com/nba/schedule/_/date/20220525' target="_blank" onClick={handleClose} style={{ textDecoration: 'none'}}>

                        
                        <Button >
                      Yes
                    </Button></a>
                  </DialogActions>
                </Box>)}


                {modalID === 'return_to_website' && (<Box pt={2}>
                    <DialogTitle id="responsive-dialog-title">
                        {"Are you sure you want to exit?"}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                      By clicking Yes, you will exit this experience and return to ComplexLand.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => handleClose()}>
                      No
                    </Button>
                    <Button onClick={() => returnToComplexland()}>
                      Yes
                    </Button>
                  </DialogActions>
                </Box>)}

    
                {modalID === 'ViewTerms' && (
                    <Box p={3}>
                        <LegalTerms />
                    </Box>
                )}


                {modalID === 'ViewPrivacyPolicy' && (
                    <Box p={3}>
                        <LegalPrivacy />
                    </Box>
                )}
            
                {open && <>
                    {/* <IconButton style={{ position: 'absolute', top: (fullScreen || isMobile) ? '5px' : '-25px', right: '5px', zIndex: 1000 }} onClick={handleClose}> */}
                    <IconButton style={{ position: 'absolute', top: '5px', right: '5px', zIndex: 1000 }} onClick={handleClose}>
                        <CloseIcon fontSize='large' style={{ position: 'fixed', zIndex: 1000, color: 'gray' }} />
                    </IconButton>
                    {/* <IconButton style={{ position: 'absolute', top: (fullScreen || isMobile) ? '5px' : '-25px', right: '55px', zIndex: 1000 }} onClick={() => setFullScreen(!fullScreen)}> */}
                    <IconButton style={{ position: 'absolute', top: '5px', right: '45px', zIndex: 1000 }} onClick={() => setFullScreen(!fullScreen)}>
                        {!fullScreen ?
                            <Fullscreen fontSize='large' style={{ position: 'fixed', zIndex: 1000, color: 'gray' }} />
                            :
                            <FullscreenExit fontSize='large' style={{ position: 'fixed', zIndex: 1000, color: 'gray' }} />
                        }
                    </IconButton>
                </>}
            </Dialog>
    </>)          
}

export default OGDialogBox